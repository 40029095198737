/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import {isMobile, getScreenHeight} from './helper.js';

const toggleMenu = () => {
	var button = document.getElementById('toggle'),
		container = document.getElementById('site-navigation'),
		html = document.querySelector('html');

		if (button && container) {
			button.addEventListener('click', () => {

				if(button.classList.contains('open')) {
					container.classList.remove('open');
					button.classList.remove('open');
					html.style.overflowY = 'auto';		
					return;
				}

				container.classList.add('open');
				button.classList.add('open');

				if (getScreenHeight() <= 300) {
					html.style.overflowY = 'hidden';
				} else {
					html.style.overflowY = 'auto';					
				}
	
			}) 
		}
} 
toggleMenu();

// window.onresize = () => {
// 	if (isMobile()) {
// 	}
// };

