const getScreenWidth = () => {
	var w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		width = w.innerWidth || e.clientWidth || g.clientWidth;
	
	return width;
}

const getScreenHeight = () => {
	var w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		height = w.innerHeight || e.clientHeight || g.clientHeight;
	
	return height;
}

const isMobile = () => {
	if (getScreenWidth() < 758)
		return true;
}

const isPhone = () => {
	if (getScreenWidth() < 440)
		return true;
}

export {isMobile, getScreenWidth, getScreenHeight}