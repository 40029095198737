//js
require('./js/helper');
require('./js/navigation');
require('./js/maps');
require('./js/main');

//fonts
// require('./fonts/font/stylesheet.css');

// css
require('./sass/style.scss');