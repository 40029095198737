(function($){

    $(document).ready(function(){

		/****** SCROLL TO #HREF *******/
		(function(){

			var names = $('body').find('a[name]');
			names.each(function(index){
				$(this).attr('id',$(this).attr('name'));
			});

			function scrollable(element) {
				var baseuri = window.location.protocol+'//'+window.location.hostname+window.location.pathname;
				var link = element[0].href;
				link = link.replace(element[0].hash,'');
				link = link.replace('#','');
				if (link.charAt(link.length -1) != '/') {
					link = link+'/';
				};

				if (link != baseuri) return false;
				if (element.hasClass('ui-tabs-anchor')) return false;
				if (element.hasClass('vc-carousel-control')) return false;
				if (element.closest('div').hasClass('opinie-pagination')) return false;
				if (element.closest('.tr-prevnext').length) return false;
				if (element.closest('nav.main-navigation').length ) return false;
				if (element.closest('h3').hasClass('wpb_accordion_header') ) return false;

				return true;
			}

			function scrollToCallback(e){
				if (scrollable($(this)))
				{
					var offset =  95;
					var item = $(this.hash);
					var h = 0;
					var w = 0;
					if (item.length > 0) {
						h = item.offset().top;
						h -= offset;
						w = item.offset().left;
					};

					$('html,body').scrollTo(w,h,{animation:{duration:900, easing:'swing'}});
					e.preventDefault();
				}
			}

			$('a[href*="#"]').on('click',scrollToCallback);

		 })();

	});

   	$(window).load(function(){

		/****** BODY PADDING TOP *******/
		(function(){

			function setBodyPaddingTop() {
				$('body').css('padding-top', $('.site-header').outerHeight());
			}
			setBodyPaddingTop();
			$(window).resize(setBodyPaddingTop);

		})();

		/****** STICKY HEADER *******/
		(function(){

			function add_header_class() {
				var window_top = $(window).scrollTop();

				if (0 < window_top) {
					$('.site-header').addClass('sticky');
				} else {
					$('.site-header').removeClass('sticky');
				}
			}

			$(window).on('scroll', add_header_class);

		})();

		/****** SLIDERS *******/
		// let sliders = {
		// 	home : $('.slider')
		// }
		// sliders.home.slick({
		// 	dots: true,
		// 	speed: 300,
		// 	asNavFor: '.slider-nav'
		// });

    });

})(jQuery);